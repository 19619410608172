<template>
	<div class="header-dropdown">
		<CDropdown in-nav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
			<template #toggler>
				<CHeaderNavLink>
					<div class="c-avatar">
						<img v-lazy="avatar" class="c-avatar-img" />
					</div>
				</CHeaderNavLink>
			</template>
			<CDropdownHeader tag="div" class="text-left" color="light">
				<strong>{{ $t("Header.Account") }}</strong>
			</CDropdownHeader>
			<!-- <CDropdownItem>
      <CIcon name="cil-task" />
      <CBadge color="danger" class="mfs-auto">{{ itemsCount }}</CBadge>
    </CDropdownItem> -->
			<CDropdownItem @click="goToProfile">
				<CIcon name="cil-user" /> {{ $t("Header.Info") }}
			</CDropdownItem>

			<CDropdownHeader tag="div" class="text-left" color="light">
				<strong>{{ $t("Header.Setting") }}</strong>
			</CDropdownHeader>
			<CDropdownItem @click="toggleChangePwd(true)">
				<CIcon name="cil-settings" /> {{ $t("Header.ChangePwd") }}
			</CDropdownItem>
			<CDropdownDivider />
			<CDropdownItem @click="toggleLogoutModal(true)">
				<CIcon name="cil-lock-locked" /> {{ $t("Header.Logout") }}
			</CDropdownItem>
		</CDropdown>
		<Logout v-if="isShowLogout" @hide="toggleLogoutModal(false)" />
		<ChangePassword v-if="isShowChangePwd" @hide="toggleChangePwd(false)" />
	</div>
</template>

<script>
import avatar from "@/assets/img/carezone.png"
import {
	CHANGE_STATUS_LOGOUT_MODAL,
	CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
} from "@/modules/authentication/store/mutation-types"
import { createNamespacedHelpers } from "vuex"
const { mapMutations, mapState } = createNamespacedHelpers("authentication")

export default {
	name: "TheHeaderDropdownAccnt",

	components: {
		Logout: () => import("@/modules/authentication/components/Logout.vue"),
		ChangePassword: () => import("@/modules/authentication/components/ChangePassword.vue"),
	},

	data() {
		return {
			avatar,
		}
	},

	computed: {
		...mapState({
			isShowLogout: "showLogoutModal",
			isShowChangePwd: "showChangePwd",
		}),
	},

	methods: {
		...mapMutations({
			CHANGE_STATUS_LOGOUT_MODAL,
			CHANGE_STATUS_CHANGE_PASSWORD_MODAL,
		}),

		toggleLogoutModal(status = false) {
			this.CHANGE_STATUS_LOGOUT_MODAL(status)
		},

		toggleChangePwd(status = false) {
			this.CHANGE_STATUS_CHANGE_PASSWORD_MODAL(status)
		},

		goToProfile() {
			this.$router.push("/profile")
		},
	},
}
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.header-dropdown {
	.c-icon {
		margin-right: 0.3rem;
	}
	.dropdown-item.active,
	.dropdown-item:active {
		background-color: unset;
		color: $color-black;
	}

	.dropdown-item:hover,
	.dropdown-item:focus {
		background-color: $sidebar-hover-color;
	}

	.dropdown-item {
		margin: 0.5em 0;
	}

	.dropdown-menu.show {
		padding-bottom: 0 !important;
	}
}
</style>
